import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Together = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>together (mini project #7)</h2>
        <p>Build a website that provides different experiences depending on the number of people connected at a given moment.</p>
        <p>Make use of socket.io and the techniques covered in the previous labs.</p>
        <br></br>
        <p>This <Link to="/other/chatroom-tutorial">tutorial</Link> will help with socket.io.</p>
        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy and deploy it on Glitch.</p>
        <p>2. in Glitch, find the Live link to the project.</p>
        <p>3. Link your room to the Riddle</p>
      </MiniLayout>
    </Layout>
  )
}



export default Together
